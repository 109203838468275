import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuthApi } from '../hooks/useAuth';
import axios from 'axios';

const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [loading, setLoading] = useState(true);
  const authApi = useAuthApi();

  useEffect(() => {
    console.log('AT >>>>> AuthProvider useEffect called');

    const initializeAuth = async () => {
      console.log('AT >>>>> initializeAuth started');
      if (!token) {
        setLoading(false);
        return;
      }

      try {
        console.log('AT >>>>> Fetching user info...');
        const { token: _, refreshToken: __, user: userData } = await authApi.getUserInfo(token);
        console.log('AT >>>>> User info received:', userData);

        if (userData) {
          setUser(userData);
        } else {
          console.log('No user data in response');
          handleLogout();
        }
      } catch (error) {
        console.error('Failed to restore auth state:', error);
        handleLogout();
      } finally {
        setLoading(false);
      }
    };

    initializeAuth();
  }, []);

  const handleLogin = (user, newToken, refreshToken) => {
    localStorage.setItem('token', newToken);
    localStorage.setItem('ug', JSON.stringify(user.GuidId));
    if (refreshToken) {
      localStorage.setItem('refreshToken', refreshToken);
    }
    setToken(newToken);
    setUser(user);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('ug');
    setToken(null);
    setUser(null);
  };

  // token이 변경될 때마다 axios 기본 헤더 업데이트
  useEffect(() => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  }, [token]);

  if (loading) {
    return null;
  }

  return (
    <AuthContext.Provider value={{ 
      user, 
      token,
      setUser, 
      loading, 
      login: handleLogin, 
      logout: handleLogout 
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }
  return context;
}
