import React from 'react';
import { Box, Paper, Typography, Switch, FormControlLabel, List, ListItem, ListItemText, Divider } from '@mui/material';
import { useTheme } from '../context/ThemeContext';

function SettingsPage() {
  const { isDarkMode, toggleTheme } = useTheme();

  return (
    <Box sx={{ p: 3, maxWidth: 800, mx: 'auto' }}>
      <Typography variant="h4" sx={{ mb: 3 }}>설정</Typography>
      
      <Paper elevation={3} sx={{ mb: 3 }}>
        <List>
          <ListItem>
            <ListItemText 
              primary="테마 설정" 
              secondary="다크 모드를 켜거나 끌 수 있습니다."
            />
            <FormControlLabel
              control={
                <Switch
                  checked={isDarkMode}
                  onChange={toggleTheme}
                />
              }
              label={isDarkMode ? '다크 모드' : '라이트 모드'}
            />
          </ListItem>
          <Divider />
          {/* 추후 다른 설정들을 추가할 수 있습니다 */}
        </List>
      </Paper>
    </Box>
  );
}

export default SettingsPage; 