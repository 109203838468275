import React, { createContext, useState, useContext } from 'react';

const CodeContext = createContext();

export function CodeProvider({ children }) {
  const [code, setCode] = useState('print("Hello, World!")');
  const [output, setOutput] = useState('');

  return (
    <CodeContext.Provider value={{ code, setCode, output, setOutput }}>
      {children}
    </CodeContext.Provider>
  );
}

export function useCode() {
  const context = useContext(CodeContext);
  if (!context) {
    throw new Error('useCode must be used within a CodeProvider');
  }
  return context;
}
