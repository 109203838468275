import React from 'react';
import { CircularProgress, Box } from '@mui/material';
import { useTheme } from '../context/ThemeContext';

const WaitingProgress = () => {
  const { isDarkMode } = useTheme();

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: isDarkMode ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)', // 반투명한 흰색 배경
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1300, // MUI의 modal보다는 낮고, 일반 컨텐츠보다는 높은 z-index
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default WaitingProgress; 
