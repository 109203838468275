import React, { useState, useEffect } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Typography,
  Box 
} from '@mui/material';
import Confetti from 'react-confetti';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ReplayIcon from '@mui/icons-material/Replay';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ListAltIcon from '@mui/icons-material/ListAlt';

const QuestNextActionModalView = ({ 
  open, 
  onClose, 
  isSuccess, 
  onRetry, 
  onNextQuiz, 
  onBackToQuest,
  hasSubmitted 
}) => {
  const [showDialog, setShowDialog] = useState(false);
  const [confettiActive, setConfettiActive] = useState(false);

  useEffect(() => {
    if (open && isSuccess) {
      setConfettiActive(true);
      setTimeout(() => {
        setShowDialog(true);
      }, 1000);
    } else {
      setShowDialog(open);
      setConfettiActive(false);
    }
  }, [open, isSuccess]);

  return (
    <>
      {confettiActive && (
        <Confetti 
          width={window.innerWidth}
          height={window.innerHeight}
          recycle={false}
          numberOfPieces={500}
          gravity={0.2}
          colors={['#FFD700', '#FFA500', '#FF6347', '#FF1493', '#00FF00', '#1E90FF']}
          tweenDuration={5000}
          onConfettiComplete={() => {
            setConfettiActive(false);
          }}
        />
      )}
      <Dialog
        open={showDialog}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ textAlign: 'center' }}>
          {isSuccess ? (
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center', 
              gap: 1,
              animation: 'bounceIn 0.5s ease-out'
            }}>
              <EmojiEventsIcon sx={{ 
                color: 'success.main', 
                fontSize: 40,
                animation: 'pulse 2s infinite'
              }} />
              <Typography variant="h4" color="success.main" sx={{ fontWeight: 'bold' }}>
                성공했습니다!
              </Typography>
            </Box>
          ) : (
            <Typography variant="h5" color="error.main">
              다시 도전해보세요!
            </Typography>
          )}
        </DialogTitle>
        <DialogContent>
          <Typography align="center" variant="h6" sx={{ mt: 2 }}>
            {!hasSubmitted ? (
              '제출을 먼저 해주세요. 제출해야 진행상황이 저장됩니다.'
            ) : isSuccess ? (
              '축하합니다! 다음 단계로 진행하시겠습니까?'
            ) : (
              '조금만 더 노력해보세요. 다시 도전하시겠습니까?'
            )}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ 
          justifyContent: 'center',
          padding: 3,
          gap: 2
        }}>
          {!isSuccess && (
            <Button 
              variant="contained" 
              color="primary"
              onClick={onRetry}
              startIcon={<ReplayIcon />}
              size="large"
            >
              재도전
            </Button>
          )}
          <Button
            variant="contained"
            color="secondary"
            onClick={onNextQuiz}
            startIcon={<ArrowForwardIcon />}
            size="large"
            disabled={!hasSubmitted}
          >
            다음 퀴즈
          </Button>
          <Button
            variant="outlined"
            onClick={onBackToQuest}
            startIcon={<ListAltIcon />}
            size="large"
            disabled={!hasSubmitted}
          >
            퀘스트로
          </Button>
        </DialogActions>
      </Dialog>

      <style>
        {`
          @keyframes bounceIn {
            0% { transform: scale(0.3); opacity: 0; }
            50% { transform: scale(1.05); opacity: 0.8; }
            70% { transform: scale(0.9); opacity: 0.9; }
            100% { transform: scale(1); opacity: 1; }
          }
          @keyframes pulse {
            0% { transform: scale(1); }
            50% { transform: scale(1.2); }
            100% { transform: scale(1); }
          }
        `}
      </style>
    </>
  );
};

export default QuestNextActionModalView; 