import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, Avatar, Menu, MenuItem, Divider, IconButton } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../context/AuthContext';
import { useAuthApi } from '../hooks/useAuth';

function Header() {
  const { user, logout } = useAuthContext();
  const { logout: apiLogout } = useAuthApi();
  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState(null);
  const [helpMenuAnchorEl, setHelpMenuAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleAvatarClick = (event) => {
    setAccountMenuAnchorEl(event.currentTarget);
  };

  const handleHelpMenuClick = (event) => {
    setHelpMenuAnchorEl(event.currentTarget);
  };

  const handleAccountMenuClose = () => {
    setAccountMenuAnchorEl(null);
  };

  const handleHelpMenuClose = () => {
    setHelpMenuAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('token');
      await apiLogout(token);
    } catch (error) {
      console.error('Logout failed:', error);
    } finally {
      logout();
      handleAccountMenuClose();
      navigate('/');
    }
  };

  
  let helpMenuItems = [
      <MenuItem key="help" component={Link} to="/usage" onClick={handleHelpMenuClose}>
        사용법
      </MenuItem>,
      <Divider key="divider1" />,
      <MenuItem key="about" component={Link} to="/about" onClick={handleHelpMenuClose}>
        About
      </MenuItem>
    ];
  
  

  let accountMenuItems;

  if (user) {
    accountMenuItems = [
      <MenuItem key="profile" disabled>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          {user.UserName}
        </Typography>
      </MenuItem>,
      <Divider key="divider1" />,
      <MenuItem key="account" component={Link} to="/account" onClick={handleAccountMenuClose}>
        계정
      </MenuItem>,
      <MenuItem key="settings" component={Link} to="/settings" onClick={handleAccountMenuClose}>
        설정
      </MenuItem>,
      <Divider key="divider2" />,
      <MenuItem key="logout" onClick={handleLogout}>
        로그아웃
      </MenuItem>
    ];
  } else {
    accountMenuItems = [
      <MenuItem key="login" component={Link} to="/login" onClick={handleAccountMenuClose}>
        로그인
      </MenuItem>,
      <MenuItem key="register" component={Link} to="/account" onClick={handleAccountMenuClose}>
        회원가입
      </MenuItem>
    ];
  }

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          파이썬 코드플레이
        </Typography>
        {/* <Button color="inherit" component={Link} to="/">대시보드</Button> */}
        {/* <Button color="inherit" component={Link} to="/quests">퀘스트</Button> */}
        <Button color="inherit" component={Link} to="/codeplay">코드플레이</Button>
        {/* <Button color="inherit" onClick={handleHelpMenuClick}>도움말</Button> */}
        <Menu
          anchorEl={helpMenuAnchorEl}
          open={Boolean(helpMenuAnchorEl)}
          onClose={handleHelpMenuClose}
          onClick={handleHelpMenuClose}
        >
          {helpMenuItems}
        </Menu>
        
        {user ? (
          <IconButton 
            onClick={handleAvatarClick}
            size="small"
            sx={{ ml: 2 }}
          >
            <Avatar sx={{ width: 32, height: 32 }}>
              {user.UserName ? user.UserName[0].toUpperCase() : ''}
            </Avatar>
          </IconButton>
        ) : (
          <Button color="inherit" onClick={handleAvatarClick}>
            <Avatar sx={{ width: 32, height: 32 }}>
              ?
            </Avatar>
          </Button>
        )}

        <Menu
          anchorEl={accountMenuAnchorEl}
          open={Boolean(accountMenuAnchorEl)}
          onClose={handleAccountMenuClose}
          onClick={handleAccountMenuClose}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {accountMenuItems}
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
