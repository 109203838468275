import { useState } from 'react';
import axios from 'axios';
import { JUDGE_API_URL } from '../config/api';

export function useJudgeApi() {
  const [isJudgeLoading, setIsJudgeLoading] = useState(false);
  const [judgeError, setJudgeError] = useState(null);

  const processResponse = (response, testCases) => {
    console.log("Raw API Response:", response.data);

    if (response.data.err) {
      return {
        success: false,
        error: response.data.err,
        errorDetail: response.data.data,
        output: `${response.data.err}: ${response.data.data}`,
        quizResults: []
      };
    }

    const results = response.data.data || [];

    const output = results.map((result, index) => 
      `CASE ${index + 1}: ${result.result === 0 ? '성공' : '실패'}\n${result.output || ''}`
    ).join('\n\n');

    const quizResults = results.map((result, index) => ({
      input: testCases[index]?.input || 'Unknown input',
      output: result.output || '출력 없음',
      expectedOutput: testCases[index]?.output || 'Unknown expected output',
      success: result.result === 0,
      cpuTime: result.cpu_time,
      memory: result.memory,
      exitCode: result.exit_code
    }));

    return {
      success: true,
      error: null,
      errorDetail: null,
      output,
      quizResults
    };
  };

  const submitCode = async (sourceCode, testCases) => {
    setIsJudgeLoading(true);
    setJudgeError(null);
    try {
      const response = await axios.post(`${JUDGE_API_URL}/api/quiz/answer_submit/`, {
        source_code: sourceCode,
        test_cases: testCases
      });
      console.log("SubmitCodeAPI Response:", response.data);
      const result = processResponse(response, testCases);
      console.log("SubmitCode Processed Result:", result);
      return result;
    } catch (err) {
      console.error("SubmitCode API Error:", err);
      setJudgeError(err.message);
      return null;
    } finally {
      setIsJudgeLoading(false);
    }
  };

  const testCode = async (sourceCode, testCases) => {
    setIsJudgeLoading(true);
    setJudgeError(null);
    try {
      const response = await axios.post(`${JUDGE_API_URL}/api/quiz/answer_test/`, {
        source_code: sourceCode,
        test_cases: testCases
      });
      console.log("API Response:", response.data);
      const result = processResponse(response, testCases);
      console.log("Processed Result:", result);
      return result;
    } catch (err) {
      console.error("API Error:", err);
      setJudgeError(err.message);
      return null;
    } finally {
      setIsJudgeLoading(false);
    }
  };

  return { submitCode, testCode, isJudgeLoading, judgeError, setIsJudgeLoading, setJudgeError };
}
