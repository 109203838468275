import React from 'react';
import { Typography, Box } from '@mui/material';

function DashboardPage() {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4">대시보드</Typography>
      <Typography>여기에 사용자의 기록을 보여줄 예정입니다.</Typography>
    </Box>
  );
}

export default DashboardPage;
