import React from 'react';
import { Box } from '@mui/material';
import { Editor as MonacoEditor } from '@monaco-editor/react';
import { useTheme } from '../context/ThemeContext';

function Editor({ code, onChange, editorRef }) {
  const { isDarkMode } = useTheme();

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
        <MonacoEditor
          height="100%"
          defaultLanguage="python"
          theme={isDarkMode ? 'vs-dark' : 'light'}
          value={code}
          onChange={onChange}
          onMount={(editor) => {
            editorRef.current = editor;
          }}
        />
      </Box>
    </Box>
  );
}

export default Editor;
