import { useState, useCallback, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../config/api';
import { useAuthContext } from '../context/AuthContext';

export function useUserQuizzes() {
  const [isUserQuizLoading, setIsUserQuizLoading] = useState(false);
  const [isUserQuizError, setIsUserQuizError] = useState(null);
  const [userQuizzes, setUserQuizzes] = useState([]);
  const [userQuiz, setUserQuiz] = useState(null);
  const [selectedSheetUserQuizzes, setSelectedSheetUserQuizzes] = useState([]);
  const [userQuizResume, setUserQuizResume] = useState([]);
  const { user, token, loading, login, logout } = useAuthContext();

  // 모든 query에 auth 헤더 추가
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  // fetchUserQuiz를 useEffect 의존성 배열보다 먼저 정의
  const fetchUserQuiz = useCallback(async (quizId) => {
    if (!user || !quizId) return;

    setIsUserQuizLoading(true);
    setIsUserQuizError(null);
    try {
      const response = await axios.get(`${API_URL}/api/ojquiz/user/${user.ID}/${quizId}`);
      setUserQuiz(response.data);
    } catch (err) {
      setIsUserQuizError(err.message);
      console.error('Failed to fetch user quiz:', err);
    } finally {
      setIsUserQuizLoading(false);
    }
  }, [user]);

    // 사용자의 모든 퀴즈 목록 조회
    const fetchUserQuizzes = useCallback(async () => {
      if (!user) return;
      
      setIsUserQuizLoading(true);
      setIsUserQuizError(null);
      try {
        const response = await axios.get(`${API_URL}/api/ojquiz/user/${user.ID}`);
        setUserQuizzes(response.data);
      } catch (err) {
        setIsUserQuizError(err.message);
        console.error('Failed to fetch user quizzes:', err);
      } finally {
        setIsUserQuizLoading(false);
      }
    }, [user]);

    const fetchUserQuizByQqid = useCallback(async (qqid) => {
      if (!user || !qqid) return;

      setIsUserQuizLoading(true);
      setIsUserQuizError(null);
      try {
        const response = await axios.get(`${API_URL}/api/ojquiz/userquiz/${qqid}`);
        setUserQuiz(response.data);
      } catch (err) {
        setIsUserQuizError(err.message);
        console.error('Failed to fetch user quiz by qqid:', err);
      } finally {
        setIsUserQuizLoading(false);
      }
    }, [user]);

    
  
  const updateUserQuizByQqid = useCallback(async (guidId, userAnswers, success) => {
    if (!user || !guidId) return;

    setIsUserQuizLoading(true);
    setIsUserQuizError(null);
    try {
      const response = await axios.put(`${API_URL}/api/ojquiz/userquiz/${guidId}`, {
        guid_id: guidId,
        user_answers: userAnswers,
        success: success
      });
      return response.data;
    } catch (err) {
      setIsUserQuizError(err.message);
      console.error('Failed to update user quiz by qqid:', err);
      return null;
    } finally {
      setIsUserQuizLoading(false);
    }
  }, [user]);



  // useEffect 순서 변경 및 의존성 정리
  // useEffect(() => {
  //   if (user) {
  //     fetchUserQuizzes();
  //   }
  // }, [user, fetchUserQuizzes]);

  // useEffect(() => {
  //   if (userQuiz) {
  //     fetchUserQuiz(userQuiz.quiz_id);
  //   }
  // }, [userQuiz, fetchUserQuiz]);



  // 특정 퀴즈시트의 사용자 퀴즈 목록 조회
  const fetchUserQuizzesBySheetId = useCallback(async (quizSheetId) => {
    if (!user || !quizSheetId) return;

    setIsUserQuizLoading(true);
    setIsUserQuizError(null);
    try {
      const response = await axios.get(`${API_URL}/api/ojquiz/user/${user.ID}/${quizSheetId}`);
      setSelectedSheetUserQuizzes(response.data);
    } catch (err) {
      setIsUserQuizError(err.message);
      console.error('Failed to fetch user quizzes by sheet:', err);
    } finally {
      setIsUserQuizLoading(false);
    }
  }, [user]);

  
  // 퀴즈시트의 퀴즈들을 사용자 퀴즈��� 생성
  const createUserQuizzesBySheetId = useCallback(async (quizSheetId) => {
    if (!user || !quizSheetId) return;

    setIsUserQuizLoading(true);
    setIsUserQuizError(null);
    try {
      const response = await axios.post(`${API_URL}/api/ojquiz/user/${user.ID}/${quizSheetId}`);
      return response.data;
    } catch (err) {
      setIsUserQuizError(err.message);
      console.error('Failed to create user quizzes:', err);
      throw err; // 에러를 상위로 전파하여 컴포넌트에서 처리할 수 있도록 함
    } finally {
      setIsUserQuizLoading(false);
    }
  }, [user]);
  // }, [user, fetchUserQuizzesBySheetId]);

  // 사용자 퀴즈 상태 업데이트
  const updateUserQuiz = useCallback(async (quizId, updateData) => {
    if (!user || !quizId) return;

    setIsUserQuizLoading(true);
    setIsUserQuizError(null);
    try {
      const response = await axios.put(`${API_URL}/api/ojquiz/${quizId}`, updateData);
      // 상태 업데이트 후 목록 새로고침
      await fetchUserQuizzes();
      return response.data;
    } catch (err) {
      setIsUserQuizError(err.message);
      console.error('Failed to update user quiz:', err);
      return null;
    } finally {
      setIsUserQuizLoading(false);
    }
  }, [user, fetchUserQuizzes]);

  // 퀴즈 진행 상태 확인 유틸리티 함수
  const getQuizStatus = useCallback((userQuiz) => {
    if (!userQuiz) return 'NOT_STARTED';
    
    switch (userQuiz.status) {
      case 0:
        return 'WAITING';
      case 50:
        return 'IN_PROGRESS';
      case 100:
        return 'SOLVED';
      default:
        return 'UNKNOWN';
    }
  }, []);

  const fetchQuizzesBySheetId = useCallback(async (quizSheetId) => {
    if (!user || !quizSheetId) return;
  }, [user, fetchUserQuizzesBySheetId]);

  // 사용자의 퀴즈 진행 상황 조회
  const fetchUserQuizResume = useCallback(async (userId) => {
    if (!userId) return;
    
    setIsUserQuizLoading(true);
    setIsUserQuizError(null);
    try {
      const response = await axios.get(`${API_URL}/api/ojquiz/user/${userId}/resume`);
      setUserQuizResume(response.data);
      return response.data;
    } catch (err) {
      setIsUserQuizError(err.message);
      console.error('Failed to fetch user quiz resume:', err);
      return null;
    } finally {
      setIsUserQuizLoading(false);
    }
  }, [user]);

  // 진행률 계산 유틸리티 함수
  const calculateProgress = useCallback((quizCount, quizSolved) => {
    if (!quizCount) return 0;
    return Math.round((quizSolved / quizCount) * 100);
  }, []);

  return {
    userQuiz,
    userQuizzes,
    selectedSheetUserQuizzes,
    userQuizResume,
    isUserQuizLoading,
    isUserQuizError,
    fetchUserQuizzes,
    fetchUserQuizzesBySheetId,
    fetchQuizzesBySheetId,
    fetchUserQuiz,
    fetchUserQuizByQqid,
    updateUserQuizByQqid,
    createUserQuizzesBySheetId,
    updateUserQuiz,
    getQuizStatus,
    fetchUserQuizResume,
    calculateProgress
  };
} 