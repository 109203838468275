import React from 'react';
import { Typography, Box } from '@mui/material';

function AboutPage() {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4">About</Typography>
      <Typography>이 애플리케이션에 대한 정보가 여기에 들어갑니다.</Typography>
    </Box>
  );
}

export default AboutPage;
