import axios from 'axios';
import { API_URL } from '../config/api';

export function useAuthApi() {
  const login = async (username, password) => {
    try {
      const response = await axios.post(`${API_URL}/auth/login`, 
        { 
          user_name: username,
          password 
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const { token, refresh_token, user } = response.data;
      console.log(`>>>>>>>>> login: t:${token}, rt:${refresh_token}, u:${user.ID}`);
      return { token, refreshToken: refresh_token, user };
    } catch (error) {
      console.error('Login error:', error.response ? error.response.data : error.message);
      throw error.response ? error.response.data : error;
    }
  };

  const getUserInfo = async (inputToken) => {
    if (!inputToken) {
      throw new Error('Token is required');
    }

    try {
      const response = await axios.post(`${API_URL}/auth/me`, {}, {
        headers: { 
          "Authorization": `Bearer ${inputToken}`,
          "Content-Type": "application/json",
        }
      });
      console.log('User info response:', response.data);
      const { user } = response.data;

      console.log(`>>>>>>>>>> getUserInfo: t:${inputToken}, u:${user.ID}`);
      return { 
        token: inputToken,    
        refreshToken: null,    
        user                  
      };
    } catch (error) {
      console.error('Get user info error details:', {
        status: error.response?.status,
        data: error.response?.data,
        message: error.message,
        config: error.config
      });
      throw error;
    }
  };

  const register = async (username, email, password) => {
    try {
      const response = await axios.post(`${API_URL}/auth/register`, 
        { 
          user_name: username,
          email, 
          password 
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Registration error:', error.response ? error.response.data : error.message);
      throw error.response ? error.response.data : error;
    }
  };

  const logout = async (token) => {
    try {
      await axios.post(`${API_URL}/auth/logout`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': "application/json"
        },
      });
    } catch (error) {
      console.error('Logout error:', error.response ? error.response.data : error.message);
    }
  };

  return { login, register, logout, getUserInfo };
}
