import React, { useState, useEffect, useRef} from 'react';
import { useLocation, Navigate, useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Switch, Stack, Paper, Typography, CircularProgress, FormControlLabel, TextareaAutosize, Tabs, Tab } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { styled } from '@mui/material/styles';
import { useTheme } from '../context/ThemeContext';
import Editor from '../components/Editor';
import Console from '../components/Console';
import { useCode } from '../context/CodeContext';
import { useJudgeApi } from '../hooks/useJudgeApi';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import { useUserQuizzes, submitUserQuiz } from '../hooks/useUserQuizzes';
import { useAuthContext } from '../context/AuthContext';
import QuestNextActionModalView from '../components/QuestNextActionModalView';
import { useQuizProgress } from '../context/QuizProgressContext';
import WaitingProgress from '../components/WaitingProgress';

const StyledTextarea = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  padding: '8px',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '4px',
  fontFamily: 'monospace',
  resize: 'none',
  minHeight: '0.9rem',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  overflow: 'auto',
  fontSize: '0.9rem',
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function PlayPageEx() {
  const params = useParams();
  const qqid = params.qqid;
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { code, setCode, output, setOutput } = useCode();
  const [history, setHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(0);
  const editorRef = useRef(null);
  const { submitCode, testCode, isJudgeLoading, judgeError, setIsJudgeLoading, setJudgeError } = useJudgeApi();
  const { updateUserQuizByQqid, fetchUserQuizByQqid, userQuiz, isUserQuizError, isUserQuizLoading } = useUserQuizzes();
  const [tabValue, setTabValue] = useState(0);
  const [eachCaseResults, setEachCaseResults] = useState([]);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [problem, setProblem] = useState({
    id: null,
    description: '',
    testCases: [],
  });
  const [visibleTestCases, setVisibleTestCases] = useState([]);
  const [executionError, setExecutionError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { moveToNextQuiz, isLastQuiz, quizSheetTitle } = useQuizProgress();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  
  // 인증 체크
  useEffect(() => {
    if (!user) {
      setShouldRedirect(true);
    }
  }, [user]);

  // 퀴즈 데이터 로드
  useEffect(() => {
    if (user && qqid) {
      fetchUserQuizByQqid(qqid);
    }
  }, [qqid, fetchUserQuizByQqid, user]);

  // 퀴즈 데이터 설정
  useEffect(() => {
    if (userQuiz && userQuiz.content) {
      setProblem({
        id: userQuiz.id,
        description: userQuiz.description || '',
        testCases: userQuiz.content.test_cases || [],
      });
      console.log(`userQuiz.id: ${userQuiz.id}, description: ${userQuiz.description}`);

      const initialCode = userQuiz.content.source_code || '';
      setCode(initialCode);
      setHistory([initialCode]);
      setHistoryIndex(0);
      setOutput('');

      const shuffled = [...userQuiz.content.test_cases].sort(() => 0.5 - Math.random());
      const newVisibleTestCases = shuffled.slice(0, 2);
      setVisibleTestCases(newVisibleTestCases);
    }
  }, [userQuiz, setCode]);

  // 로그인 페이지로 리디렉션
  if (shouldRedirect) {
    return <Navigate to="/login" replace />;
  }

  const handleEditorChange = (value) => {
    setCode(value);
    setHistory(prev => [...prev.slice(0, historyIndex + 1), value]);
    setHistoryIndex(prev => prev + 1);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleTest = async () => {
    const result = await testCode(code, problem.testCases);
    setIsJudgeLoading(true);
    if (result) {
      if (result.success) {
        setOutput(result.output);
        setEachCaseResults(result.quizResults);
        setExecutionError(null);
        setIsJudgeLoading(false);
      } else {
        setOutput(result.output);
        setEachCaseResults([]);
        setExecutionError({
          type: result.error,
          detail: result.errorDetail
        });
        setIsJudgeLoading(false);
      }
    }
  };

  const handleSubmit = async () => {
    const result = await submitCode(code, problem.testCases);
    setIsJudgeLoading(true);
    if (result) {
      ///////////////////////////////////////////////////////////////////////////////////////////////
      // result.sucess => 코드실행결과 CASE의 성공여부와 무관함. 코드가 일단 실행되면, 무조건 true임. 
      ///////////////////////////////////////////////////////////////////////////////////////////////
      if (result.success) {
        setOutput(result.output);
        setEachCaseResults(result.quizResults);
        setExecutionError(null);
        setIsJudgeLoading(false);
      } else {
        setOutput(result.output);
        setEachCaseResults([]);
        setExecutionError({
          type: result.error,
          detail: result.errorDetail
        });
        setIsJudgeLoading(false);
      }
      // CASE에 '실패' 문구가 하나라도 있는지 검사함. 
      //{success: true, error: null, errorDetail: null, output: 'CASE 1: 실패'... }
      const anyFailed = result.output.includes('실패');
      setIsSuccess(!anyFailed);
      
      const response = await updateUserQuizByQqid(
        qqid,
        code,
        !anyFailed
      );
      if (response) {
        console.log(`userQuiz 저장 완료: ${response.success}`);
        setHasSubmitted(true);
      }

      setModalOpen(true);
    }
  };

  const handleReset = () => {
    if (userQuiz) {
      setCode(userQuiz.content.source_code || '');
      setHistory([userQuiz.content.source_code || '']);
      setHistoryIndex(0);
    }
    setOutput('');
    setEachCaseResults([]);
  };

  const handleUndo = () => {
    if (historyIndex > 0) {
      setHistoryIndex(prev => prev - 1);
      setCode(history[historyIndex - 1]);
    }
  };

  const handleRedo = () => {
    if (historyIndex < history.length - 1) {
      setHistoryIndex(prev => prev + 1);
      setCode(history[historyIndex + 1]);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleRetry = () => {
    setModalOpen(false);
  };

  const handleNextQuiz = () => {
    setModalOpen(false);
    const nextQuizId = moveToNextQuiz(qqid);
    console.log(`>>>>> nextQuizId: ${nextQuizId}`);
    
    if (nextQuizId) {
      // 다음 퀴즈로 이동
      navigate(`/quizquest/${nextQuizId}`);
    } else {
      // 마지막 퀴즈였다면 퀴즈 목록으로 돌아가기
      navigate('/quizquest', { 
        state: { 
          quizSheetId: userQuiz.quizsheet_id,
          quizSheetTitle: quizSheetTitle
        } 
      });
    }
  };

  const handleJudgeError = (error) => {
    setJudgeError(error);
    setIsJudgeLoading(false);
  };

  if (judgeError) {
    handleJudgeError(judgeError);
  }

  const handleBackToQuest = () => {
    setModalOpen(false);
    // navigate(`/quizquest`);
    navigate(-1);
  };

  if (isUserQuizLoading || isJudgeLoading) {
    return <WaitingProgress />;
  }

  if (isUserQuizError) {
    return <Typography color="error">{isUserQuizError}</Typography>;
  }

  if (!userQuiz) {
    return <Typography>퀴즈를 찾을 수 없습니다.</Typography>;
  }

  return (
    <>
      <Box sx={{ 
        flexGrow: 1, 
        m: 2, 
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        height: { xs: 'auto', md: 'calc(100vh - 100px)' },
        gap: 2
      }}>
        {userQuiz ? (
          <>
            <Box sx={{ 
              width: { xs: '100%', md: '33.33%' },
              height: { xs: 'auto', md: '100%' },
              overflow: 'auto'
            }}>
              <Paper elevation={3} sx={{ 
                height: { xs: 'auto', md: '100%' }, 
                display: 'flex', 
                flexDirection: 'column',
                mb: { xs: 2, md: 0 }
              }}>
                <Box sx={{ p: 1}}>
                  <Stack direction="row" spacing={2} sx={{ p: 1, justifyContent: 'space-between', borderBottom: '1px solid gray' }}>
                    <Typography variant="h6">{userQuiz?.title}</Typography>
                    <Typography variant="subtitle1">★{quizSheetTitle}</Typography>
                  </Stack>
                </Box>
                <Box sx={{ flexGrow: 1, overflow: 'auto', p: 1 }}>
                  {/* <Typography variant="h6" sx={{ mb: 2 }}>{userQuiz?.title}</Typography> */}
                  <ReactMarkdown
                    rehypePlugins={[rehypeRaw]}
                    remarkPlugins={[remarkGfm, remarkBreaks]}
                    components={{
                      p: ({ node, ...props }) => (
                        <Typography 
                          component="div" 
                          sx={{ 
                            whiteSpace: 'pre-line',
                            mb: 0.5,        // 문단 간격 축소 (1 -> 0.5)
                            lineHeight: 1.1, // 줄 간격 축소
                            '& + &': {      // 연속된 문단 사이 간격
                              mt: 0.5
                            }
                          }} 
                          {...props} 
                        />
                      ),
                      pre: ({ node, ...props }) => (
                        <Box 
                          component="pre" 
                          sx={{ 
                            whiteSpace: 'pre-wrap',
                            wordBreak: 'break-word',
                            backgroundColor: 'background.paper',
                            p: 1.5,         // 패딩 축소 (2 -> 1.5)
                            borderRadius: 1,
                            mb: 1.5,        // 하단 마진 축소 (2 -> 1.5)
                            lineHeight: 1.1  // 코드 블록 줄 간격
                          }} 
                          {...props} 
                        />
                      ),
                      code: ({ node, inline, ...props }) => 
                        inline 
                          ? (
                            <Typography 
                              component="code" 
                              sx={{ 
                                backgroundColor: 'background.paper',
                                p: 0.3,      // 패딩 축소 (0.5 -> 0.3)
                                borderRadius: 0.5,
                                lineHeight: 1.1
                              }} 
                              {...props} 
                            />
                          )
                          : (
                            <Box 
                              component="code" 
                              sx={{ 
                                display: 'block',
                                whiteSpace: 'pre-wrap',
                                wordBreak: 'break-word',
                                p: 0.8,      // 패딩 축소 (1 -> 0.8)
                                borderRadius: 1,
                                lineHeight: 1.1
                              }} 
                              {...props} 
                            />
                          ),
                      // 리스트 항목의 간격도 조정
                      li: ({ node, ...props }) => (
                        <Typography 
                          component="li" 
                          sx={{ 
                            mb: 0.3,        // 리스트 항목 간 간격
                            lineHeight: 1.3
                          }} 
                          {...props} 
                        />
                      ),
                    }}
                  >
                    {problem.description}
                  </ReactMarkdown>
                </Box>
                <Box sx={{ p: 1 }}>
                  <Typography variant="h6" sx={{ mb: 1 }}>테스트 케이스</Typography>
                  {visibleTestCases.map((testCase, index) => (
                    <Box key={index} sx={{ mb: 2 }}>
                      <Paper elevation={1} sx={{ p: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1 }}>
                          <Typography variant="body1" sx={{ mr: 2, minWidth: '60px', mt: 1 }}>입력 {index + 1}:</Typography>
                          <StyledTextarea
                            value={testCase.input}
                            readOnly
                            minRows={2}
                            maxRows={5}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                          <Typography variant="body1" sx={{ mr: 2, minWidth: '60px', mt: 1 }}>출력 {index + 1}:</Typography>
                          <StyledTextarea
                            value={testCase.output}
                            readOnly
                            minRows={1}
                            maxRows={5}
                          />
                        </Box>
                      </Paper>
                    </Box>
                  ))}
                </Box>
              </Paper>
            </Box>
            <Box sx={{ 
              width: { xs: '100%', md: '66.67%' },
              height: { xs: 'auto', md: '100%' },
              display: 'flex',
              flexDirection: 'column'
            }}>
              <Stack spacing={2} sx={{ height: { xs: 'auto', md: '100%' } }}>
                <Paper elevation={3} sx={{ 
                  height: { xs: '400px', md: '60%' },
                  display: 'flex', 
                  flexDirection: 'column'
                }}>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center', 
                    p: 0.5, 
                    backgroundColor: 'background.paper' 
                  }}>
                    <Typography variant="subtitle1" sx={{ ml: 1 }}>main.py</Typography>
                    <Box>
                      <Button variant="contained" color="primary" onClick={handleTest} sx={{ mr: 0.5 }} size="small" disabled={isJudgeLoading}>
                        테스트
                      </Button>
                      <Button variant="contained" color="secondary" onClick={handleSubmit} sx={{ mr: 0.5, py: 0.5 }} size="small" disabled={isJudgeLoading}>
                        제출
                      </Button>
                      <Button variant="contained" color="secondary" onClick={handleReset} sx={{ mr: 0.5 }} size="small">
                        리셋
                      </Button>
                    </Box>
                  </Box>
                  <Box sx={{ flexGrow: 1 }}>
                    <Editor 
                      code={code} 
                      onChange={handleEditorChange} 
                      editorRef={editorRef}
                    />
                  </Box>
                </Paper>
                <Paper elevation={3} sx={{ 
                  height: { xs: '300px', md: 'calc(40% - 16px)' },
                  display: 'flex', 
                  flexDirection: 'column'
                }}>
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center', 
                    p: 0.5, 
                    backgroundColor: 'background.paper' 
                  }}>
                    <Typography variant="subtitle1" sx={{ ml: 1 }}>콘솔 출력</Typography>
                  </Box>
                  {executionError ? (
                    <Box sx={{ p: 2, color: 'error.main' }}>
                      <Typography variant="h6" color="error">
                        {executionError.type}
                      </Typography>
                      <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                        {executionError.detail}
                      </Typography>
                    </Box>
                  ) : eachCaseResults && eachCaseResults.length > 0 ? (
                    <Box sx={{ mt: 0.5 }}>
                      <Tabs value={tabValue} onChange={handleTabChange} aria-label="test case tabs" variant="scrollable" scrollButtons="auto">
                        {eachCaseResults.map((result, index) => (
                          <Tab 
                            key={index} 
                            label={
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <span>{`CASE ${index + 1}`}</span>
                                {result.success ? (
                                  <CheckCircleOutlineIcon color="success" sx={{ ml: 1 }} />
                                ) : (
                                  <CancelOutlinedIcon color="error" sx={{ ml: 1 }} />
                                )}
                              </Box>
                            } 
                          />
                        ))}
                      </Tabs>
                      {eachCaseResults.map((result, index) => (
                        <TabPanel key={index} value={tabValue} index={index}>
                          <Box>
                            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>입력: {result.input}</Typography>
                          </Box>
                          <Box sx={{ mt: 2 }}>
                        <Typography 
                          variant="body1" 
                          sx={{ 
                            fontWeight: 'bold',
                            whiteSpace: 'pre-wrap',
                            fontFamily: 'monospace',
                            maxHeight: '110px',  // 최대 높이 설정
                            overflowY: 'auto'    // 세로 스크롤 추가
                          }}
                          >
                            출력: {'\n'}{result.output}
                          </Typography>
                        </Box>
                        </TabPanel>
                      ))}
                    </Box>
                  ) : (
                    <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                      <Console output={output} />
                    </Box>
                  )}
                </Paper>
              </Stack>
            </Box>
          </>
        ) : (
          <Box sx={{ p: 3 }}>
            <Typography variant="h6">
              퀴즈가 선택되지 않았습니다. 학습 메뉴에서 퀴즈를 선택해주세요.
            </Typography>
          </Box>
        )}
      </Box>
      
      <QuestNextActionModalView 
        open={modalOpen}
        onClose={handleModalClose}
        isSuccess={isSuccess}
        onRetry={handleRetry}
        onNextQuiz={handleNextQuiz}
        onBackToQuest={handleBackToQuest}
        hasSubmitted={hasSubmitted}
      />
    </>
  );
}

export default PlayPageEx; 