import { useState } from 'react';
import axios from 'axios';

const JUDGE0_API_URL = process.env.REACT_APP_JUDGE0_API_URL;

export function useJudge0Api() {
  const [isJudge0ApiLoading, setIsJudge0ApiLoading] = useState(false);
  const [judge0ApiError, setJudge0ApiError] = useState(null);

  const executeCode = async (sourceCode) => {
    setIsJudge0ApiLoading(true);
    setJudge0ApiError(null);
    try {
      const response = await axios.post(`${JUDGE0_API_URL}/api/submit_code/`, {
        source_code: sourceCode
      });

      // 실행 결과 처리
      if (response.data.error) {
        return {
          success: false,
          error: response.data.error,
          output: response.data.error
        };
      }

      const {
        stdout = '',
        stderr = '',
        compile_output = '',
        message = '',
        status = {}
      } = response.data;

      // 출력 형식 결정
      let output = '';
      const statusId = status.id;

      if (statusId === 3) {  // 정상 실행
        output = stdout ? `stdout:\n${stdout}` : '실행 결과가 없습니다.';
        return {
          success: true,
          output
        };
      } 
      
      if (statusId === 11) {  // 런타임 에러 (NZEC)
        output = [
          `상태: ${status.description || ''}`,
          message ? `메시지: ${message}` : '',
          stderr ? `stderr:\n${stderr}` : '',
          stdout ? `stdout:\n${stdout}` : ''
        ].filter(Boolean).join('\n');

        return {
          success: false,
          error: '실행 오류',
          output
        };
      }

      // 기타 오류
      output = [
        `기타 오류: ${status.description || '알 수 없는 오류'}`,
        message ? `메시지: ${message}` : '',
        stderr ? `stderr:\n${stderr}` : '',
        stdout ? `stdout:\n${stdout}` : '',
        compile_output ? `컴파일 오류:\n${compile_output}` : ''
      ].filter(Boolean).join('\n');

      return {
        success: false,
        error: '실행 오류',
        output
      };

    } catch (err) {
      setJudge0ApiError(err.message);
      return {
        success: false,
        error: '실행 중 오류가 발생했습니다.',
        output: err.message
      };
    } finally {
      setIsJudge0ApiLoading(false);
    }
  };

  return { executeCode, isJudge0ApiLoading, judge0ApiError, setJudge0ApiError };
} 