import React, { useState, useEffect, useRef } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Typography, Box, TextField, Button, Tab, Tabs, CircularProgress, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuthContext } from '../context/AuthContext';
import { useAuthApi } from '../hooks/useAuth';

function LoginPage() {
  const [tab, setTab] = useState(0);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { login, loading, error, logout, user, token, refreshToken } = useAuthContext();
  const { login: apiLogin, register: apiRegister } = useAuthApi();
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const { token, refreshToken, user } = await apiLogin(username, password);
      login(user, token, refreshToken);
      navigate('/');
    } catch (error) {
      console.error('Login failed:', error);
      alert(error.message || '로그인에 실패했습니다. 다시 시도해 주세요.');
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      await apiRegister(username, email, password);
      setTab(0); // 회원가입 후 로그인 탭으로 전환
      alert('회원가입이 완료되었습니다. 로그인해 주세요.');
    } catch (error) {
      console.error('Registration failed:', error);
      alert(error.message || '회원가입에 실패했습니다. 다시 시도해 주세요.');
    }
  };

  return (
    <Box sx={{ p: 3, width: '40%', margin: 'auto' }}>
      <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)}>
        <Tab label="로그인" />
        {/* <Tab label="회원가입" /> */}
      </Tabs>
      {tab === 0 ? (
        <form onSubmit={handleLogin}>
          <TextField
            label="사용자 이름"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="비밀번호"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
            slotProps={{
              input: {
                endAdornment: <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            }}
          />
          <Button type="submit" variant="contained" color="primary" sx={{ mt: 2, width: '100%' }}>
            로그인
          </Button>
        </form>
      ) : (
        <form onSubmit={handleRegister}>
          <TextField
            label="사용자 이름"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="이메일"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="비밀번호"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button type="submit" variant="contained" color="primary">
            회원가입
          </Button>
        </form>
      )}
    </Box>
  );
}

export default LoginPage;
