export const API_URL = process.env.REACT_APP_CSLMS_APP_API_URL;
export const JUDGE_API_URL = process.env.REACT_APP_JUDGE_API_URL;
export const JUDGE0_API_URL = process.env.REACT_APP_JUDGE0_API_URL;

// 환경 확인용 로그 (개발 중에만 표시)
if (process.env.NODE_ENV === 'development') {
  console.log('Current Environment:', process.env.NODE_ENV);
  console.log('CSLMS APP API URL:', API_URL);
  console.log('Judge API URL:', JUDGE_API_URL);
  console.log('Judge0 API URL:', JUDGE0_API_URL);
}
