import React, { useState, useEffect, useRef} from 'react';
import { useLocation, Navigate, useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Switch, Stack, Paper, Typography, CircularProgress, FormControlLabel, TextareaAutosize, Tabs, Tab } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import UndoOutlined from '@mui/icons-material/UndoOutlined';
import RedoOutlined from '@mui/icons-material/RedoOutlined';
import { styled } from '@mui/material/styles';
import { useTheme } from '../context/ThemeContext';
import Editor from '../components/Editor';
import Console from '../components/Console';
import { useCode } from '../context/CodeContext';
import { useJudge0Api } from '../hooks/useJudge0Api';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import { useUserQuizzes, submitUserQuiz } from '../hooks/useUserQuizzes';
import { useAuthContext } from '../context/AuthContext';
import QuestNextActionModalView from '../components/QuestNextActionModalView';
import { useQuizProgress } from '../context/QuizProgressContext';
import WaitingProgress from '../components/WaitingProgress';

const StyledTextarea = styled(TextareaAutosize)(({ theme }) => ({
  width: '100%',
  padding: '8px',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '4px',
  fontFamily: 'monospace',
  resize: 'none',
  minHeight: '0.9rem',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  overflow: 'auto',
  fontSize: '0.9rem',
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function CodePlayEx() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { code, setCode, output, setOutput } = useCode();
  const [history, setHistory] = useState([]);
  const [historyIndex, setHistoryIndex] = useState(0);
  const editorRef = useRef(null);
  const { executeCode, isJudge0ApiLoading, judge0ApiError, setJudge0ApiError } = useJudge0Api();
  const [executionError, setExecutionError] = useState(null);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  
  useEffect(() => {
    if (!user) {
      setShouldRedirect(true);
    }
  }, [user]);

  if (shouldRedirect) {
    return <Navigate to="/login" replace />;
  }

  const handleEditorChange = (value) => {
    setCode(value);
    setHistory(prev => [...prev.slice(0, historyIndex + 1), value]);
    setHistoryIndex(prev => prev + 1);
  };

  const handleExecuteCode = async () => {
    setOutput('');
    setExecutionError(null);
    
    const result = await executeCode(code);
    console.log(">>>>> Execute Result:", result);
    
    if (result) {
      if (result.success) {
        setOutput(result.output);
      } else {
        if (result.error) {
          setExecutionError({
            type: '실행 오류',
            // detail: result.error
            detail: result.output
          });
        } else {
          setOutput(result.output);
        }
      }
    }
  };

  const handleReset = () => {
    setCode('');
    setHistory(['']);
    setHistoryIndex(0);
    setOutput('');
    setExecutionError(null);
  };

  const handleUndo = () => {
    if (historyIndex > 0) {
      setHistoryIndex(prev => prev - 1);
      setCode(history[historyIndex - 1]);
    }
  };

  const handleRedo = () => {
    if (historyIndex < history.length - 1) {
      setHistoryIndex(prev => prev + 1);
      setCode(history[historyIndex + 1]);
    }
  };

  return (
    <>
      {isJudge0ApiLoading && <WaitingProgress />}
      <Box sx={{ 
        flexGrow: 1, 
        m: 2, 
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        height: { xs: 'auto', md: 'calc(100vh - 100px)' },
        gap: 2
      }}>
        <Box sx={{ 
          width: { xs: '100%', md: '33.33%' },
          height: { xs: 'auto', md: '100%' },
          overflow: 'auto'
        }}>
          <Paper elevation={3} sx={{ 
            height: { xs: 'auto', md: '100%' }, 
            display: 'flex', 
            flexDirection: 'column',
            mb: { xs: 2, md: 0 }
          }}>
            <Box sx={{ p: 1}}>
              <Stack direction="row" spacing={2} sx={{ p: 1, justifyContent: 'space-between', borderBottom: '1px solid gray' }}>
                <Typography variant="h6">코드 플레이</Typography>
              </Stack>
            </Box>
            <Box sx={{ flexGrow: 1, overflow: 'auto', p: 1 }}>
              <Typography>
                자유롭게 파이썬 코드를 작성하고 실행해보세요.
              </Typography>
            </Box>
          </Paper>
        </Box>
        
        <Box sx={{ 
          width: { xs: '100%', md: '66.67%' },
          height: { xs: 'auto', md: '100%' },
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Stack spacing={2} sx={{ height: { xs: 'auto', md: '100%' } }}>
            <Paper elevation={3} sx={{ 
              height: { xs: '400px', md: '60%' },
              display: 'flex', 
              flexDirection: 'column'
            }}>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                p: 0.5, 
                backgroundColor: 'background.paper' 
              }}>
                <Typography variant="subtitle1" sx={{ ml: 1 }}>main.py</Typography>
                <Box>
                  <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleExecuteCode} 
                    sx={{ mr: 0.5 }} 
                    size="small" 
                    disabled={isJudge0ApiLoading}
                  >
                    <PlayArrowIcon />
                  </Button>
                  <Button 
                    variant="contained" 
                    color="secondary" 
                    onClick={handleReset} 
                    sx={{ mr: 0.5 }} 
                    size="small"
                  >
                    <RestartAltIcon />
                  </Button>
                  <Button 
                    variant="contained" 
                    color="secondary" 
                    onClick={handleUndo} 
                    sx={{ mr: 0.5 }} 
                    size="small"
                  >
                    <UndoOutlined />
                  </Button>
                  <Button 
                    variant="contained" 
                    color="secondary" 
                    onClick={handleRedo} 
                    sx={{ mr: 0.5 }} 
                    size="small"
                  >
                    <RedoOutlined />
                  </Button>
                </Box>
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Editor 
                  code={code} 
                  onChange={handleEditorChange} 
                  editorRef={editorRef}
                />
              </Box>
            </Paper>
            <Paper elevation={3} sx={{ 
              height: { xs: '300px', md: 'calc(40% - 16px)' },
              display: 'flex', 
              flexDirection: 'column'
            }}>
              <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                alignItems: 'center', 
                p: 0.5, 
                backgroundColor: 'background.paper' 
              }}>
                <Typography variant="subtitle1" sx={{ ml: 1 }}>콘솔 출력</Typography>
              </Box>
              {executionError ? (
                <Box sx={{ p: 2, color: 'error.main' }}>
                  <Typography variant="h6" color="error">
                    {executionError.type}
                  </Typography>
                  <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                    {executionError.detail}
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                  <Console output={output} />
                </Box>
              )}
            </Paper>
          </Stack>
        </Box>
      </Box>
    </>
  );
}

export default CodePlayEx; 