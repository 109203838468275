import React, { useEffect, useState } from 'react';
import { Typography, Box, Stack, Card, CardContent, CardActions, Button } from '@mui/material';
import { useAuthContext } from '../context/AuthContext';
import { useUserQuizzes} from '../hooks/useUserQuizzes';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { useQuizProgress } from '../context/QuizProgressContext';
import WaitingProgress from '../components/WaitingProgress';

function QuizQuestPage() {
  const { user, token, loading, login, logout } = useAuthContext();
  const { 
    isUserQuizLoading, 
    isUserQuizError, 
    fetchUserQuizzesBySheetId, 
    selectedSheetUserQuizzes,
    createUserQuizzesBySheetId 
  } = useUserQuizzes();
  const navigate = useNavigate();
  const location = useLocation();
  const quizSheetId = location.state?.quizSheetId;
  const quizSheetTitle = location.state?.quizSheetTitle;
  const { initializeQuizSequence } = useQuizProgress();

  useEffect(() => {
    if (user && quizSheetId) {
      fetchUserQuizzesBySheetId(quizSheetId);
    }
  }, [fetchUserQuizzesBySheetId, quizSheetId, user]);

  useEffect(() => {
    if (selectedSheetUserQuizzes && selectedSheetUserQuizzes.length > 0) {
      initializeQuizSequence(selectedSheetUserQuizzes, quizSheetId, quizSheetTitle);
    }
  }, [selectedSheetUserQuizzes, quizSheetId, quizSheetTitle]);

  const handleStartQuest = async () => {
    try {
      const result = await createUserQuizzesBySheetId(quizSheetId);
      if (result) {
        await fetchUserQuizzesBySheetId(quizSheetId);
      }
    } catch (error) {
      console.error('Failed to start quest:', error);
    }
  };

  if (!user) {
    return <Navigate to="/account" />;
  }

  if (!quizSheetId) {
    return <Navigate to="/quests" />;
  }

  if (isUserQuizLoading) {
    return <WaitingProgress />;
  }

  if (isUserQuizError) {
    return <Typography color="error">{isUserQuizError}</Typography>;
  }

  // selectedSheetUserQuizzes가 null이거나 undefined인 경우 빈 배열로 처리
  const quizzes = selectedSheetUserQuizzes || [];
  const sortedQuizzes = quizzes.sort((a, b) => a.ordering - b.ordering);

  const handleAttempt = (quiz) => {
    navigate('/playex', { 
      state: { 
        quizId: quiz.quiz_id, 
        isNewAttempt: true 
      } 
    });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>{quizSheetTitle || '퀴즈제목'}</Typography>
      {quizzes.length === 0 ? (
        <>
          <Typography>
            {user.user_name}님은 아직 이 퀘스트를 시작하지 않아서, 등록된 퀴즈가 없습니다.
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleStartQuest}
              disabled={isUserQuizLoading}
            >
              {isUserQuizLoading ? (
                <WaitingProgress />
              ) : (
                `"${quizSheetTitle}" 퀘스트 시작하기`
              )}
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Typography variant="body1" sx={{ mb: 3 }}>
            아래의 퀴즈들을 순서대로 풀어보세요.
          </Typography>
          <Stack 
          direction={{ xs: 'column', sm: 'row' }} 
            spacing={3} 
            useFlexGap 
            flexWrap="wrap"
          >
            {sortedQuizzes.map((quiz, index) => (
              <Card key={quiz.id || index} sx={{ 
                width: { xs: '100%', sm: 'calc(50% - 16px)', md: 'calc(33.33% - 16px)' },
                display: 'flex', 
                flexDirection: 'column' 
              }}>
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h6" component="div" sx={{ mb: 2 }}>
                    {`${index + 1}. ${quiz.quiz_title || '제목 없음'}`}
                  </Typography>
                  <Box sx={{ maxHeight: 150, overflow: 'auto', mb: 2 }}>
                    <ReactMarkdown>{quiz.description || ''}</ReactMarkdown>
                  </Box>
                  <Box sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 1 
                  }}>
                    {quiz.status === 100 ? (
                      <CheckCircleIcon color="success" />
                    ) : quiz.status === 59 ? (
                      <ErrorIcon color="error" />
                    ) : (
                      <HourglassEmptyIcon color="action" />
                    )}
                    <Typography 
                      color={quiz.status === 100 ? 'success.main' : quiz.status === 59 ? 'error.main' : 'text.secondary'}
                      sx={{ fontWeight: 'bold' }}
                    >
                      {quiz.status === 100 ? '완료' : quiz.status === 59 ? '실패' : '대기중'}
                    </Typography>
                  </Box>
                </CardContent>
                <CardActions>
                  <Button 
                    size="small" 
                    color="primary" 
                    onClick={() => navigate(`/quizquest/${quiz.guid_id}`)}
                    variant="contained"
                  >
                    도전하기
                  </Button>
                </CardActions>
              </Card>
            ))}
          </Stack>
        </>
      )}
    </Box>
  );
}

export default QuizQuestPage;
