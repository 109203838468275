import React from 'react';
import { Typography, Box } from '@mui/material';

function UsagePage() {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4">사용법</Typography>
      <Typography>이 애플리케이션의 사용법에 대한 설명이 여기에 들어갑니다.</Typography>
    </Box>
  );
}

export default UsagePage;
