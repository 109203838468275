import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from './context/ThemeContext';
import { AuthProvider } from './context/AuthContext';
import { CodeProvider } from './context/CodeContext';
import { QuizProgressProvider } from './context/QuizProgressContext';
import Header from './components/Header';
import PlayPageEx from './pages/QuestPlayPage';
import QuestListPage from './pages/QuestListPage';
import AccountPage from './pages/AccountPage';
import AboutPage from './pages/AboutPage';
import UsagePage from './pages/UsagePage';
import DashboardPage from './pages/DashboardPage';
import SettingsPage from './pages/SettingsPage';
import LoginPage from './pages/LoginPage';
import QuizesOfQuestPage from './pages/QuizesOfQuestPage';
import CodePlayEx from './pages/CodePlayEx';

function App() {
  return (
    <AuthProvider>
      <QuizProgressProvider>
        <ThemeProvider>
          <CodeProvider>
            <CssBaseline />
            <Router>
              <Header />
              <Routes>
                <Route path="/" element={<DashboardPage />} />
                <Route path="/playex" element={<PlayPageEx />} />
                <Route path="/quests" element={<QuestListPage />} />
                <Route path="/account" element={<AccountPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/usage" element={<UsagePage />} />
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/quizquest" element={<QuizesOfQuestPage />} />
                <Route path="/codeplay" element={<CodePlayEx />} />
              </Routes>
            </Router>
          </CodeProvider>
        </ThemeProvider>
      </QuizProgressProvider>
    </AuthProvider>
  );
}

export default App;
