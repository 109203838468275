import React from 'react';
import { Box, Typography } from '@mui/material';

function Console({ output }) {
  return (
    <Box sx={{ 
      p: 2, 
      fontFamily: 'monospace', 
      whiteSpace: 'pre-wrap',
      overflow: 'auto'
    }}>
      <Typography 
        component="pre" 
        sx={{ 
          fontFamily: 'monospace',
          margin: 0
        }}
      >
        {output}
      </Typography>
    </Box>
  );
}

export default Console;
