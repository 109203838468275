import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Typography, Box, TextField, Button, Tab, Tabs, CircularProgress, Stack, InputAdornment } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useAuthContext } from '../context/AuthContext';
import { useUserInfo } from '../hooks/useUserInfo';
import StatusChip from '../components/StatusChip';
import WaitingProgress from '../components/WaitingProgress';

function AccountPage() {
  const { user } = useAuthContext();
  const [tab, setTab] = useState(0);
  const { userInfo, isUserInfoLoading, userInfoError, fetchUserByGuid } = useUserInfo();

  useEffect(() => {
    if (user?.GuidId) {
      fetchUserByGuid(user.GuidId);
    }
  }, [user, fetchUserByGuid]);

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (isUserInfoLoading) {
    return <WaitingProgress />;
  }

  if (userInfoError) {
    return <Typography color="error">{userInfoError}</Typography>;
  }

  const accountFields = userInfo ? [
    { label: '이름', value: userInfo.DisplayName },
    { label: '학년', value: userInfo.GradeLevel },
    { label: '내 전화번호', value: userInfo.PhoneOwn },
    { label: '메일주소', value: userInfo.Email },
    { label: '보호자 전화번호', value: userInfo.PhoneParent },
    { label: '코딩스쿨 아이디', value: userInfo.UserName },
    { 
      label: '상태', 
      value: userInfo.UserStatus,
      renderValue: (value) => <StatusChip type="student" value={value} />
    },
    { label: '유저타입', value: userInfo.UserType }
  ] : [];

  return (
    <Box sx={{ p: 3 }}>
      <Tabs 
        value={tab} 
        onChange={(e, newValue) => setTab(newValue)}
        sx={{ mb: 4 }}
      >
        <Tab label="계정" />
        <Tab label="기타" />
      </Tabs>

      {tab === 0 ? (
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            {accountFields.map((field, index) => (
              <Grid item size={{xs: 12, md: 6}} key={index}>
                <TextField
                  label={field.label}
                  value={field.renderValue ? '' : (field.value || '')}
                  disabled
                  fullWidth
                  variant="outlined"
                  slotProps={{
                    input: {
                      startAdornment: field.renderValue && 
                        <InputAdornment position="start">
                          {field.renderValue(field.value)}
                        </InputAdornment>
                    },
                    sx: {
                      fontSize: '1.0rem',
                      backgroundColor: 'background.paper'
                    }
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      ) : (
        <Box>
          <Button variant="contained" color="primary">
            기타 정보 
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default AccountPage;
