import React, { createContext, useContext, useState } from 'react';

const QuizProgressContext = createContext(null);

export function QuizProgressProvider({ children }) {
  const [quizSequence, setQuizSequence] = useState([]);  // [{guid_id, ordering}] 배열
  const [currentOrdering, setCurrentOrdering] = useState(null);
  const [quizSheetId, setQuizSheetId] = useState(null);
  const [quizSheetTitle, setQuizSheetTitle] = useState(null);

  const initializeQuizSequence = (quizzes, sheetId, sheetTitle) => {
    const sequence = quizzes
      .map(quiz => ({
        guid_id: quiz.guid_id,
        ordering: quiz.ordering
      }))
      .sort((a, b) => a.ordering - b.ordering);
    
    setQuizSequence(sequence);
    setCurrentOrdering(sequence[0]?.ordering || null);
    setQuizSheetId(sheetId);
    setQuizSheetTitle(sheetTitle);
  };

  const moveToNextQuiz = (currentGuidId) => {
    const currentIndex = quizSequence.findIndex(q => q.guid_id === currentGuidId);
    if (currentIndex === -1) return null;

    // 다음 퀴즈가 있는 경우
    if (currentIndex < quizSequence.length - 1) {
      const nextQuiz = quizSequence[currentIndex + 1];
      setCurrentOrdering(nextQuiz.ordering);
      return nextQuiz.guid_id;
    }
    
    // 마지막 퀴즈인 경우
    return null;
  };

  const getCurrentQuiz = () => {
    return quizSequence.find(q => q.ordering === currentOrdering);
  };

  const isLastQuiz = (guidId) => {
    const index = quizSequence.findIndex(q => q.guid_id === guidId);
    return index === quizSequence.length - 1;
  };

  return (
    <QuizProgressContext.Provider value={{
      quizSequence,
      currentOrdering,
      quizSheetId,
      quizSheetTitle,
      initializeQuizSequence,
      moveToNextQuiz,
      getCurrentQuiz,
      isLastQuiz
    }}>
      {children}
    </QuizProgressContext.Provider>
  );
}

export function useQuizProgress() {
  const context = useContext(QuizProgressContext);
  if (!context) {
    throw new Error('useQuizProgress must be used within a QuizProgressProvider');
  }
  return context;
} 