import React, { useEffect } from 'react';
import { Typography, Box, Stack, Card, CardContent, CardActions, Button, CircularProgress, LinearProgress } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useAuthContext } from '../context/AuthContext';
import { useUserQuizzes } from '../hooks/useUserQuizzes';
import { Navigate, useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

function QuestListPage() {
  const { user, loading, token, login, logout } = useAuthContext();
  const { 
    userQuizResume, 
    userQuizLoading, 
    userQuizError, 
    fetchUserQuizResume,
    calculateProgress 
  } = useUserQuizzes();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (user) {
      fetchUserQuizResume(user.ID);
    }
  }, [fetchUserQuizResume, user]);

  if (!user) {
    return <Navigate to="/account" />;
  }

  if (userQuizLoading) {
    return <CircularProgress 
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%'
      }}
    />;
  }

  if (userQuizError) {
    return <Typography color="error">{userQuizError}</Typography>;
  }

  if (!userQuizResume || userQuizResume.length === 0) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography>진행 가능한 퀘스트가 없습니다.</Typography>
      </Box>
    );
  }

  // 퀴즈를 ordering 순서대로 정렬
  const sortedQuizResume = [...userQuizResume].sort((a, b) => {
    return (a.ordering || 0) - (b.ordering || 0);
  });

  const handleStartQuest = (quizResume) => {
    navigate('/quizquest', { 
      state: { 
        quizSheetId: quizResume.quizsheet_id,
        quizSheetTitle: quizResume.quizsheet_title
      } 
    });
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>퀘스트 목록</Typography>
      <Typography variant="body1" sx={{ mb: 3 }}>
        단계별로 구성된 파이썬 프로그래밍 학습 퀘스트입니다. 순서대로 진행하시면 됩니다.
      </Typography>
      <Stack 
        direction={{ xs: 'column', sm: 'row' }} 
        spacing={3} 
        useFlexGap 
        flexWrap="wrap"
      >
        {sortedQuizResume.map((quizResume, index) => (
          <Card key={quizResume.quizsheet_id} sx={{ 
            width: { xs: '100%', sm: 'calc(50% - 16px)', md: 'calc(33.33% - 16px)' },
            display: 'flex', 
            flexDirection: 'column',
            position: 'relative'
          }}>
            <CardContent sx={{ flexGrow: 1 }}>
              <Typography variant="h6" component="div" sx={{ mb: 2 }}>
                {`${index + 1}. ${quizResume.quizsheet_title || '제목 없음'}`}
              </Typography>
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                  진행률: {calculateProgress(quizResume.quiz_count, quizResume.quiz_solved)}%
                </Typography>
                <LinearProgress 
                  variant="determinate" 
                  value={calculateProgress(quizResume.quiz_count, quizResume.quiz_solved)}
                  sx={{ height: 8, borderRadius: 1 }}
                />
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  {`완료: ${quizResume.quiz_solved} / ${quizResume.quiz_count} 문제`}
                </Typography>
              </Box>
            </CardContent>
            <CardActions>
              <Button 
                size="small" 
                color="primary" 
                onClick={() => handleStartQuest(quizResume)}
                variant="contained"
                startIcon={<PlayArrowIcon />}
              >
                퀘스트 시작
              </Button>
            </CardActions>
          </Card>
        ))}
      </Stack>
    </Box>
  );
}

export default QuestListPage; 