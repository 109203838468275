import { useState, useCallback } from 'react';
import axios from 'axios';
import { API_URL } from '../config/api';
import { useAuthContext } from '../context/AuthContext';

export function useUserInfo() {
  const [isUserInfoLoading, setIsUserInfoLoading] = useState(false);
  const [userInfoError, setUserInfoError] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const { token, user } = useAuthContext();

  // ID로 사용자 정보 조회
  const fetchUserById = useCallback(async (userId) => {
    if (!userId) return;

    setIsUserInfoLoading(true);
    setUserInfoError(null);
    try {
      const response = await axios.get(`${API_URL}/users/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setUserInfo(response.data);
      return response.data;
    } catch (err) {
      setUserInfoError(err.message);
      console.error('Failed to fetch user info:', err);
    } finally {
      setIsUserInfoLoading(false);
    }
  }, [token]);

  // GUID로 사용자 정보 조회
  const fetchUserByGuid = useCallback(async () => {
    const guid = user?.GuidId;
    if (!guid) return;

    setIsUserInfoLoading(true);
    setUserInfoError(null);
    try {
      const response = await axios.get(`${API_URL}/users/guid/${guid}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setUserInfo(response.data);
      return response.data;
    } catch (err) {
      setUserInfoError(err.message);
      console.error('Failed to fetch user info by guid:', err);
    } finally {
      setIsUserInfoLoading(false);
    }
  }, [token]);

  return {
    userInfo,
    isUserInfoLoading,
    userInfoError,
    fetchUserById,
    fetchUserByGuid
  };
} 